import { autoinject, bindable, observable } from 'aurelia-framework';
import { ErrorService } from 'services/error-service';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';
import { ProductMaterialType } from 'models';
import {
  CertificateProducer,
  CheckDuplicateMooringArticleProduct,
  CreateMooringArticle,
  CriteriaForValidityCategory,
  MooringArticle,
  MooringArticleCategory,
  MooringArticleType,
  MooringProductType,
  MooringSupplier,
  categoryDimensionTypes,
  getArticleWeightType,
} from 'models/mooring';
import {
  CriteriaForValidityService,
  MooringArticleCategoryService,
  MooringArticleService,
  MooringArticleTypeService,
  MooringProductTypeService,
  ProductMaterialTypeService,
} from 'services';
import { MooringArticleProduct } from 'models/mooring/MooringArticleProduct';
import { ProductService } from 'services/product-service';
import { SupplierService } from 'services/supplier-service';
import { MooringArticleMaterial } from 'models/mooring/MooringArticleMaterial';

@autoinject
export class MooringArticleCreateEdit {
  protected ready = false;

  @bindable
  locked = false;

  @bindable
  protected isSaving = false;

  @bindable
  @observable
  model: MooringArticle;
  modelChanged(model: MooringArticle) {
    this.ready = this.ready || false;
    this.resetForm();
    if (!model || !model?.Id) {
      this.setReady();
      return;
    }

    this.form = JSON.parse(JSON.stringify(model)) as MooringArticle;

    this.setCategoryDimensionTypeText();
    this.setFilteredTypes();
    this.setReady();
  }

  protected setReady() {
    setTimeout(() => {
      this.ready = true;
      this.setCertificateProducer(this.form.OverrideCertificateProducer);
    }, 100);
  }

  protected morenotProducesCertificate = false;

  @bindable
  onSave: (data: { data: CreateMooringArticle }) => void;

  @bindable
  onCanceled: () => void;

  protected categories: MooringArticleCategory[] = [];
  protected types: MooringArticleType[] = [];
  protected typesByCategory: MooringArticleType[] = [];

  protected productTypes: MooringProductType[] = [];
  protected productTypesByType: MooringProductType[] = [];

  protected suppliers: MooringSupplier[] = [];
  private productToDelete: MooringArticleProduct[] = [];
  private materialsToDelete: MooringArticleMaterial[] = [];

  private allMaterials: ProductMaterialType[] = [];
  protected get materials() {
    return JSON.parse(JSON.stringify(this.allMaterials));
  }

  protected weightUnitLabel = '';

  protected isDuplicateMooringArticleProducts: boolean = false;
  protected duplicateMooringArticleProductsErrorMessage: string = '';

  protected form: CreateMooringArticle = {
    Id: undefined,
    MBL: undefined,
    Inactive: false,
    TypeId: undefined,
    CategoryId: undefined,
    ProductTypeId: undefined,
    SupplierId: undefined,
    WeightInAir: undefined,
    WeightInWater: undefined,
    PrimaryDimension: undefined,
    SecondaryDimension: undefined,
    ArticleProducts: [],
    MooringArticleMaterials: [],
  };

  protected dimensionType = {
    primary: '',
    secondary: '',
  };

  constructor(
    private errorService: ErrorService,
    private validationController: ValidationController,
    private validator: Validator,
    private mooringArticleService: MooringArticleService,
    private mooringArticleCategoryService: MooringArticleCategoryService,
    private mooringArticleTypeService: MooringArticleTypeService,
    private mooringProductTypeService: MooringProductTypeService,
    private supplierService: SupplierService,
    private productService: ProductService,
    private criteriaForValidityCategoryService: CriteriaForValidityService,
    private t: I18N,
    private productMaterialsService: ProductMaterialTypeService
  ) {}

  protected applyValidationRules() {
    ValidationRules.ensure('CategoryId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('CategoryId')
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('TypeId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('TypeId')
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('SupplierId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('SupplierId')
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .on(this.form);
  }

  protected async attached() {
    await this.load();
  }

  protected resetForm() {
    this.form = {
      CategoryId: undefined,
      Id: undefined,
      Inactive: false,
      SupplierId: undefined,
      TypeId: undefined,
      ProductTypeId: undefined,
      MBL: undefined,
      WeightInAir: undefined,
      WeightInWater: undefined,
      PrimaryDimension: undefined,
      SecondaryDimension: undefined,
      ArticleProducts: [],
      MooringArticleMaterials: [],
    };
    this.productToDelete = [];
    this.validationController.reset();
  }

  private async load() {
    const [categories, types, productTypes, suppliers, allMaterials] = await Promise.all([
      this.mooringArticleCategoryService.getAll(),
      this.mooringArticleTypeService.getAll(),
      this.mooringProductTypeService.getAll(),
      this.supplierService.getAll(),
      this.productMaterialsService.getAll('?$expand=ProductMaterialCategory'),
      this.getCriteriaForValidityCategories(),
    ]);

    this.categories = categories;
    this.types = types;
    this.productTypes = productTypes;
    this.suppliers = suppliers;
    this.allMaterials = allMaterials;

    setTimeout(() => {
      this.setCategoryDimensionTypeText();
      this.setProducer();
      this.validationController.reset();
      this.ready = true;
    });
  }

  private setProducer() {
    const supplier = this.suppliers.find((x) => x.Id == this.form?.SupplierId);
    if (!supplier) return;
    this.morenotProducesCertificate = supplier?.CertificateProducer == CertificateProducer.MORENOT;
  }

  private setFilteredTypes() {
    setTimeout(() => {
      if (this.form?.CategoryId && this.types.length) {
        this.typesByCategory = [];
        setTimeout(() => {
          this.typesByCategory = this.types.filter((x) => +x.CategoryId == +this.form?.CategoryId);
        });
      }

      if (this.form?.TypeId && this.productTypes.length) {
        this.productTypesByType = [];
        setTimeout(() => {
          this.productTypesByType = this.productTypes.filter((x) => +x.ArticleTypeId == +this.form?.TypeId);
        });
      }
    }, 350);
  }

  protected addMaterial() {
    if (!this.form.MooringArticleMaterials) this.form.MooringArticleMaterials = [];
    const amountInProduct = this.form.MooringArticleMaterials?.reduce?.((acc, x) => acc + (+x.MaterialAmountInProduct || 0), 0) || 0;

    this.form.MooringArticleMaterials.push({
      Id: undefined,
      MooringArticleId: undefined,
      MaterialTypeId: undefined,
      MaterialAmountType: 0,
      MaterialAmountInProduct: Math.max(100 - (+amountInProduct || 0), 0),
      Name: '_',
      NameEn: '_',
      NameEs: '_',
    });
  }
  protected removeMaterial(index: number, product: MooringArticleMaterial) {
    const toDelete = this.form.MooringArticleMaterials.splice(index, 1).at(0);
    if (product.Id) this.materialsToDelete.push(toDelete);
  }

  protected setMaterialId(index: number, materialTypeId: number) {
    this.form.MooringArticleMaterials[index].MaterialTypeId = +materialTypeId;
  }

  protected addProduct() {
    if (!this.form.ArticleProducts) this.form.ArticleProducts = [];
    this.form.ArticleProducts.push({
      Id: undefined,
      ProductId: undefined,
      MooringArticleId: undefined,
    });
  }

  protected getProduct(id: number) {
    return this.productService.get(id);
  }

  protected removeProduct(index: number, product: MooringArticleProduct) {
    const toDelete = this.form.ArticleProducts.splice(index, 1).at(0);
    if (product.Id) this.productToDelete.push(toDelete);
  }

  protected mblIsLocked = true;

  protected async setProductId(index: number, product: { value: number }) {
    this.form.ArticleProducts[index].ProductId = product.value;
    const navProduct = await this.getProduct(product.value);
    if (navProduct && navProduct.MinBreakingStrength) {
      this.form.MBL = navProduct.MinBreakingStrength;
    }
  }

  protected setProductTypeId(productTypeId: number) {
    this.form.ProductTypeId = +productTypeId || null;
    void this.validate();
  }

  protected setTypeId(typeId: number) {
    if (this.form.TypeId !== typeId) {
      this.form.ProductTypeId = undefined;
    }

    this.form.TypeId = +typeId || null;
    void this.validate();
    this.setFilteredTypes();
  }

  protected setCategoryDimensionTypeText() {
    const category = this.categories.find((x) => x.Id == this.form?.CategoryId);
    if (!category) return;

    this.weightUnitLabel = this.t.tr(getArticleWeightType(category?.WeightUnitType)) || '';

    this.dimensionType = {
      primary: categoryDimensionTypes.find((x) => x.value == category?.PrimaryDimensionType)?.label || '',
      secondary: categoryDimensionTypes.find((x) => x.value == category?.SecondaryDimensionType)?.label || '',
    };
  }

  protected setCategoryId(categoryId: number) {
    if (this.form.CategoryId !== categoryId) {
      this.form.TypeId = null;
      this.form.ProductTypeId = null;
    }

    this.form.CategoryId = +categoryId || null;
    this.setCategoryDimensionTypeText();
    void this.validate();
    this.setFilteredTypes();
  }

  protected setCertificateProducer(producer?: CertificateProducer) {
    producer = +producer;
    if (Number.isNaN(producer) || typeof producer !== 'number') return;

    this.form.OverrideCertificateProducer = +producer;
    this.morenotProducesCertificate = producer == CertificateProducer.MORENOT;
    void this.validate();
  }

  protected setSupplierId(supplierId: number) {
    const supplier = this.suppliers.find((x) => x.Id == supplierId);
    if (!supplier) return;
    this.morenotProducesCertificate = supplier?.CertificateProducer == CertificateProducer.MORENOT;
    this.form.OverrideCertificateProducer = supplier?.CertificateProducer;
    this.form.SupplierId = +supplierId || null;
    void this.validate();
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    return (await this.validationController.validate({ object: this.form })).valid;
  }

  protected criteriaForValidityCategories: CriteriaForValidityCategory[] = [];
  protected async getCriteriaForValidityCategories() {
    try {
      this.criteriaForValidityCategories = await this.criteriaForValidityCategoryService.getAll(`?$orderby=Name asc`);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async submit() {
    try {
      if (await this.checDuplicate()) return;

      this.applyValidationRules();
      const valid = await this.validate();
      if (!valid) return;

      await Promise.all(this.productToDelete.map((x) => this.mooringArticleService.removeArticleProduct(x.MooringArticleId, x.Id)));

      await Promise.all(this.materialsToDelete.map((x) => this.mooringArticleService.removeMaterial(x.MooringArticleId, x.Id)));

      this.form.ArticleProducts = this.form.ArticleProducts.filter((x) => x.ProductId);
      this.form.MooringArticleMaterials = this.form.MooringArticleMaterials.filter((x) => x.MaterialTypeId);

      this.onSave?.({ data: this.form });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async checDuplicate() {
    const checkDuplicateMooringArticleProducts: CheckDuplicateMooringArticleProduct[] = [];
    const articleProducts = this.form.ArticleProducts.filter((x) => x.ProductId);

    if (articleProducts.length > 0) {
      articleProducts.forEach((articleProduct) => {
        const mooringArticleProduct: CheckDuplicateMooringArticleProduct = {
          Id: articleProduct.Id,
          MooringArticleId: articleProduct.MooringArticleId,
          ProductId: articleProduct.ProductId,
          SupplierId: this.form.SupplierId,
        };
        checkDuplicateMooringArticleProducts.push(mooringArticleProduct);
      });

      const duplicateMooringArticleProducts = await this.mooringArticleService.checkDuplicate(checkDuplicateMooringArticleProducts);
      if (duplicateMooringArticleProducts.length > 0) {
        this.duplicateMooringArticleProductsErrorMessage = this.t.tr('general.duplicateMooringArticleProducts');
        duplicateMooringArticleProducts.forEach((mooringArticleProduct, index) => {
          const istLast = index == duplicateMooringArticleProducts.length - 1;
          if (!istLast) {
            this.duplicateMooringArticleProductsErrorMessage =
              this.duplicateMooringArticleProductsErrorMessage + ' ' + mooringArticleProduct + ', ';
          } else {
            this.duplicateMooringArticleProductsErrorMessage =
              this.duplicateMooringArticleProductsErrorMessage + ' ' + mooringArticleProduct + '.';
          }
        });
        this.isDuplicateMooringArticleProducts = true;
        return true;
      } else {
        this.isDuplicateMooringArticleProducts = false;
        return false;
      }
    } else {
      return false;
    }
  }

  cancel() {
    this.onCanceled?.();
  }
}
