import { DialogController, DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { MooringTemplateDto, MooringTemplateListItemDto } from 'models/mooring';
import { AsyncErrorHandler } from 'lib/ui';
import { ToastService } from 'services';
import { MooringComponentTemplateService } from 'services/mooring/mooring-component-temlate-service';
import { FilterManager } from 'lib/tables/FilterManager';
import { PubSub } from 'lib/event/PubSub';
import { TableHeader } from 'components/table/table-content';
import { MooringCreateUpdateTemplateDialog, UpdateTemplateDialogArgs } from './mooring-create-template-dialog';

export type ComponentTemplateType = 'anchor-line' | 'bridle' | 'grid-line' | 'buoy';

export type SelectTemplateDialogArgs = {
  type: ComponentTemplateType;
};

export type SelectTemplateDialogResult = {
  template?: MooringTemplateDto;
};

@autoinject
export class MooringSelectTemplateDialog {
  protected tableHeaders: TableHeader<MooringTemplateListItemDto & { actions: ''; Select: boolean }>[] = [
    { key: 'Select', label: '', sortable: false },
    { key: 'Id', label: 'general.id', sortable: true },
    { key: 'Name', label: 'general.name', sortable: true },
    { key: 'Created', label: 'general.created', sortable: true },
    { key: 'CreatedBy', label: 'general.createdBy', sortable: true },
    { key: 'actions', label: '', sortable: false },
  ];
  protected templates: MooringTemplateListItemDto[] = [];

  protected selectedTemplateId: number;

  protected type: ComponentTemplateType;

  constructor(
    private dialogSerivce: DialogService,
    private dialogController: DialogController,
    private toastService: ToastService,
    private mooringComponentTemplateService: MooringComponentTemplateService,
    private fm: FilterManager,
    private pubsub: PubSub
  ) {
    pubsub.sub('filter-manager:filter-changed', () => {
      this.getTemplates();
    });
  }

  @AsyncErrorHandler
  protected async getTemplates() {
    let task: Promise<{ data: MooringTemplateListItemDto[] }>;
    switch (this.type) {
      case 'anchor-line': {
        task = this.mooringComponentTemplateService.getAnchorLineTemplates(this.fm.queryFilter);
        break;
      }
      case 'grid-line': {
        task = this.mooringComponentTemplateService.getGridLineTemplates(this.fm.queryFilter);
        break;
      }
      case 'buoy': {
        task = this.mooringComponentTemplateService.getBuoyTemplates(this.fm.queryFilter);
        break;
      }
      case 'bridle': {
        task = this.mooringComponentTemplateService.getBridleTemplates(this.fm.queryFilter);
        break;
      }
    }
    const { data } = await task;
    this.templates = data;
  }

  protected async init(args: SelectTemplateDialogArgs) {
    this.type = args.type;
    await this.getTemplates();
  }

  protected activate(args?: SelectTemplateDialogArgs) {
    if (!args) {
      this.toastService.showError('Template args is missing');
    }

    this.fm.init({
      context: `mooring-template-select-${args.type}`,
      storageKey: `mooring-template-select-${args.type}`,
      filters: {},
      defaultFilters: {},
    });

    void this.init(args);
  }

  protected selectTemplate(index: number) {
    console.log(index);
  }

  @AsyncErrorHandler
  async applyTemplate() {
    if (!this.selectedTemplateId) return;

    let task: Promise<{ data: MooringTemplateDto }>;
    switch (this.type) {
      case 'anchor-line': {
        task = this.mooringComponentTemplateService.getAnchorLineTemplate(this.selectedTemplateId);
        break;
      }
      case 'grid-line': {
        task = this.mooringComponentTemplateService.getGridLineTemplate(this.selectedTemplateId);
        break;
      }
      case 'buoy': {
        task = this.mooringComponentTemplateService.getBuoyTemplate(this.selectedTemplateId);
        break;
      }
      case 'bridle': {
        task = this.mooringComponentTemplateService.getBridleTemplate(this.selectedTemplateId);
        break;
      }
    }
    const { data: template } = await task;
    void this.dialogController.cancel({ template });
  }

  protected setOrderByColumn(column: string) {
    this.fm.setOrderByColumn(column);
  }

  protected async openTemplate(index: number) {
    this.dialogSerivce.open({
      viewModel: MooringCreateUpdateTemplateDialog,
      position: () => 0,
      model: {
        action: 'update',
        type: this.type,
        templateId: this.templates.at(index).Id,
      } satisfies UpdateTemplateDialogArgs,
    });
  }

  onCancel() {
    this.pubsub.unsub();
    void this.dialogController.cancel();
  }

  protected detached() {
    this.pubsub.unsub();
  }

  protected unbind() {
    this.pubsub.unsub();
  }
}
