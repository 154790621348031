import { autoinject } from 'aurelia-framework';
import { MooringArticleSearchResult, MooringComponentTemplateEntryDto } from 'models/mooring';
import { MooringComponentSearchHandler } from 'services/mooring';
import { ToastService } from 'services';
import { DialogController } from 'aurelia-dialog';
import { I18N } from 'aurelia-i18n';
import { SearchResult } from 'elements/search/search-input';

export type MooringArticleFinderDialogResult = {
  component: MooringComponentTemplateEntryDto;
};

@autoinject
export class MooringArticleFinderDialog {
  protected articleId?: number;

  protected component: MooringComponentTemplateEntryDto;
  protected hasLoadConstraint = true;

  constructor(
    protected t: I18N,
    protected toast: ToastService,
    private dialogController: DialogController,
    protected mooringComponentSearchHandler: MooringComponentSearchHandler
  ) {}

  protected onArticleSelected(searchResult: SearchResult) {
    const res = searchResult.return as MooringArticleSearchResult;
    this.articleId = res.Id;
    this.component = {
      Id: 0,
      WLL: 0,
      SortIndex: 0,
      MBL: res.MBL,
      Quantity: 1,
      TypeName: res.Type,
      ArticleId: res.Id,
      ProductName: res.Name,
      CategoryName: res.Category,
      ProductTypeName: res.ProductType,
      HasLoadConstraint: true,
    };
  }

  protected apply() {
    this.component.HasLoadConstraint = this.hasLoadConstraint;
    this.dialogController.close(false, { component: this.component });
  }

  protected onCancel() {
    this.dialogController.close(false, { articleId: undefined });
  }
}
