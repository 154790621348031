import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';

export type TemplatePopulateSelection = { id: number; name: string; selected: boolean };

export type MooringSelectEntriesForPupulateArgs = {
  options: { name: string; id: number }[];
};

export type MooringSelectEntriesForPupulateResult = {
  selections: TemplatePopulateSelection[];
};

@autoinject
export class MooringSelectEntriesForPupulateDialog {
  protected selections: TemplatePopulateSelection[] = [];

  constructor(private dialogController: DialogController) {}

  protected activate({ options }: MooringSelectEntriesForPupulateArgs) {
    this.selections = options?.map(({ id, name }) => ({ id, name, selected: true }));
  }

  protected clearSelections() {
    this.selections.forEach((x) => (x.selected = false));
  }

  protected onDone() {
    void this.dialogController.cancel({ selections: this.selections });
  }

  protected onCancel() {
    void this.dialogController.cancel();
  }
}
